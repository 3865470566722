import Customers from '../components/Customers/Customers';
import Brochure from '../components/Brochure/Brochure';
import Publications from '../components/Publications/Publications';
import Services from '../components/Services/Services';
import Identity from '../components/Identity/Identity';
import Contact from '../components/Contact/Contact';
import Team from '../components/Team/Team';

function Home() {
    return (
        <div>
            <Identity/>
            <Team/>
            <Services/>
            <Brochure/>
            <Publications/>
            <Customers/>
            <Contact/>
        </div>
    );
}

export default Home;

