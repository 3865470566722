import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import contact from './Footer.png';
import iconocelular from './IconoCelular.svg';
import iconoemail from './IconoEmail.svg';
import logo from './Logo.png';

import './Contact.scss';

function SeeContact() {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();
    return (
        <div id="idcontacto" className="row">
            <div className="col-12">
                <div class="row pt-2">
                    <h4>
                        <p className="text-left mb-0 font-weight-bold contact-textblack responsive-global-title-text"> {t('ContactTitle1')} </p>
                        <p className="text-left font-weight-bold contact-textred responsive-global-title-text"> {t('ContactTitle2')} </p>
                    </h4>
                </div>
                <div class="row">
                    <img src={iconocelular} class="contact-img"/>
                    <spam class="text-left responsive-global-description-text"> &nbsp; Perú +51 959 726 974</spam>
                </div>
                <br/>
                <div class="row">
                    <img src={iconocelular} class="contact-img"/>
                    <spam class="text-left responsive-global-description-text"> &nbsp; Perú +51 996 606 646</spam>
                </div>
                <br/>
                <div class="row">
                    <img src={iconoemail} class="contact-img"/>
                    <spam class="text-left responsive-global-description-text"> &nbsp; consulting@dendurcorporation.com</spam>
                </div>
            </div>
        </div>
   );
}

function Contact() {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();
    return (
        <div className="row-fluid position-relative">
            <img className="img-fluid position-relative card-img-top border-0" src={contact} alt="Contact background" />
            <div className="position-absolute contact-content-overlay responsive-padding">
                <SeeContact />
            </div>
            <img src={logo} className="responsive-logofooter"/>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <p class="responsive-global-descriptionxs-text contact-footer">© DENDUR CORPORATION. {t('Footer')} </p>
            </div>

        </div>
    );
}

export default Contact;
