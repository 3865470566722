import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ItemsCarousel from 'react-items-carousel';
import { p, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import services from './Servicios.png';
import service1 from './services1.png';
import service2 from './services2.png';
import service3 from './services3.png';
import service4 from './services4.png';
import service5 from './services5.png';
import service6 from './services6.png';
import service7 from './services7.png';
import serviceen1 from './servicesen1.png';
import serviceen2 from './servicesen2.png';
import serviceen3 from './servicesen3.png';
import serviceen4 from './servicesen4.png';
import serviceen5 from './servicesen5.png';
import serviceen6 from './servicesen6.png';
import serviceen7 from './servicesen7.png';
import back from './back.svg';
import forward from './forward.svg';
import linea from './LineasServicio.svg';

import './Services.scss';
//import Carousel from "react-bootstrap/Carousel";
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';

function DendurCarousel({ items }) {
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const chevronWidth = 220;
    return (
        <div class="col position-absolute services-zposition">
        <div style={{ padding: `0 ${chevronWidth}px`}}>
            <ItemsCarousel
                requestToChangeActive={setActiveItemIndex}
                activeItemIndex={activeItemIndex}
                numberOfCards={3}
                gutter={20}
                leftChevron={<button className="services-button"><img src={back}/></button>}
                rightChevron={<button className="services-button"><img src={forward}/></button>}
                outsideChevron
                chevronWidth={chevronWidth}
            >
                <div />
                { items.map((item, index) => (
                    <div className="p-0" style={{
                        transform: 'scale(0.7)',
                        transition: 'transform 1s', transform: activeItemIndex === (index) ? 'scale(0.7)' : 'scale(0.5)'
                    }}>
                        <img src={item.img}/>
                    </div>
                )) }
                <div />
            </ItemsCarousel>
            {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
                {items.map((item, index) => (
                    <div style={{
                        backgroundColor: activeItemIndex === index ? 'red' : 'gray',
                        height: 15,
                        width: 15,
                        marginLeft: 10
                    }}></div>
                ))}
            </div> */}
        </div>
        </div>
    );
}


function SeeServicesInicio() {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();
    return (
        <div className="row p-0">
            <div className="col-4">
                <h4>
                    <p className="text-left responsive-global-title-text font-weight-bold services-textwhite mb-0"> {t('ServicesTitle1')} </p>
                    <p className="text-left responsive-global-title-text font-weight-bold services-textred mb-0"> {t('ServicesTitle2')} </p>
                </h4>
            </div>
        </div>
    );
}
function Inicio() {
    return (
        <div className="row-fluid position-relative">
            <img className="img-fluid position-relative card-img-top border-0" src={services} alt="Services background" />
            <div className="position-absolute services-content-overlay responsive-padding">
                <SeeServicesInicio/>
            </div>
        </div>

    );
}

function ExploreMore() {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();
    return (
        <div className="row-fluid">
            <br/><br/><br/><br/><br/><br/><br/><br/><br/>
            <br/><br/><br/><br/>
            <div align="center">
                <p className="responsive-global-description-text"> {t('Services1')} <br/><br/></p>
                <a class="button text-button" role="button"><Link to="/services" className="button-link"> {t('Services2')} </Link></a>
            </div>
            <br/><br/><br/>
        </div>
    );
}


function Services() {
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        }
    };
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();
    
    return (
        <div className="container-fluid p-0">
            <Inicio/>
            <div className="row-fluid position-relative p-0">
                <DendurCarousel
                    items={[
                        {
                            key: '1',
                            img: i18n.language === 'en' ? serviceen1 : service1,
                        },
                        {
                            key: '2',
                            img: i18n.language === 'en' ? serviceen2 : service2,
                        },
                        {
                            key: '3',
                            img: i18n.language === 'en' ? serviceen3 : service3,
                        },
                        {
                            key: '4',
                            img: i18n.language === 'en' ? serviceen4 : service4,
                        },
                        {
                            key: '5',
                            img: i18n.language === 'en' ? serviceen5 : service5,
                        },
                        {
                            key: '6',
                            img: i18n.language === 'en' ? serviceen6 : service6,
                        },
                        {
                            key: '7',
                            img: i18n.language === 'en' ? serviceen7 : service7,
                        },
                        
                    ]}

                />
            </div>
        <ExploreMore/>
        </div>
    );
}


export default Services;
