import { Link, useLocation } from 'react-router-dom'
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { NavHashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import Image from 'react-bootstrap/Image';
import './Header.scss';

import logo from './LOGO.svg';
import iconocelular from './IconoCelular.svg';
import iconoemail from './IconoEmail.svg';
import esact from './ES_ROJO.svg';
import esdes from './ES_BLANCO.svg';
import enact from './EN_ROJO.svg';
import endes from './EN_BLANCO.svg'; 
import bandEEUU from './BAN_EEUU.svg';
import bandPeru from './BAN_PERU.svg';
import i18n from '../../i18n';

const numbersHeight = 0;
    
function Header() {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();
    
    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
    };

    return (
        <Navbar bg="light" expand="lg" className="navbar-dendur navbar-light fixed-top header-navbar" style={{ marginTop: numbersHeight,paddingTop: 14,paddingBottom:14}}>
            <Navbar.Brand href="#home">
                <div className = "row">
                    <div className = "col-4">
                        <img src={logo} className="d-inline-block align-top responsive-logo" />
                    </div>
                    <div className = "col-5">
                        <img src={iconocelular} width="18px" height="18px"/><span className="pl-1 responsive-global-descriptionxs-text">+51 959 726 974 &nbsp;&nbsp; </span>
                        <img src={iconocelular} width="18px" height="18px"/><span className="pl-1 responsive-global-descriptionxs-text">+51 996 606 646</span><br/>
                        <img src={iconoemail} width="18px" height="18px"/> <span className="pl-1 responsive-global-descriptionxs-text">consulting@dendurcorporation.com</span>
                    </div>
                </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <div class="flex-column ml-auto">
                    <Nav className="ml-auto justify-content-center">
                        <Nav.Link><NavHashLink to="/home#idhome" activeClassName="link-selected" className="responsive-global-description-text"> {t('Start')} </NavHashLink></Nav.Link>
                        <Nav.Link><NavHashLink to="/home#idnosotros" activeClassName="link-selected" className="responsive-global-description-text"> {t('Our')} </NavHashLink></Nav.Link>
                        <Nav.Link><NavHashLink to="/home#iddiferencia"  activeClassName="link-selected" className="responsive-global-description-text"> {t('Diference')} </NavHashLink></Nav.Link>
                        <Nav.Link><Link to="/services" activeClassName="link-selected" className="responsive-global-description-text"> {t('Services')} </Link></Nav.Link>
                        <Nav.Link><Link  className={ pathname === '/publishing' ? 'link-selected' : ''}  to="/publishing" className="responsive-global-description-text"> {t('Publications')} </Link></Nav.Link>
                        <Nav.Link><NavHashLink to="/home#idcontacto"  activeClassName="link-selected" className="responsive-global-description-text"> {t('Contact')} </NavHashLink></Nav.Link>
                        {/* <button className="header-language" onClick={() => changeLanguage('es')}><img src = { i18n.language === 'en' ? esdes : esact} width="30px" height="30px"/></button>
                        <button className="header-language" onClick={() => changeLanguage('en')}><img src = { i18n.language === 'en' ? enact : endes} width="30px" height="30px"/></button> */}
                        {/* <div class="img-select">
                                <span class="img-option endes"></span>
                                <select>
                                    <option value="kite" onClick={() => changeLanguage('es')}>Español</option>
                                    <option value="snowboard" onClick={() => changeLanguage('en')}>Ingles</option>
                                </select>
                        </div>
                         */}
                         <Dropdown>
                            <Dropdown.Toggle variant="light" className="bg-white border-0">
                                {t('SelectLanguaje')}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => changeLanguage('es')}>
                                    <span>
                                        <Image src={bandPeru} width={20}/>
                                        <span className="ml-1">Español</span>
                                    </span>
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => changeLanguage('en')}>
                                    <span>
                                        <Image src={bandEEUU} width={20}/>
                                        <span className="ml-1">Ingles</span>
                                    </span>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Nav>
                </div>
            </Navbar.Collapse>
        </Navbar>
    );
}
export default Header;
