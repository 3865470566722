import PublicationsDet from '../components/PublicationsDet/PublicationsDet';

function Publishing() {
    return (
        <div>
            <PublicationsDet/>
        </div>
    );
}

export default Publishing;

