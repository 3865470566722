import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import ClienteLaOnza from './ClienteLaOnza.svg';
import ClienteIGH from './ClienteIGH.svg';
import Mistodemo from './Mistodemo.png';
import Solmintras from './Solmintras.png';
import Aura from './Aura.png';

import './Customers.scss';

function Customers() {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();
    return (
        <div className="customers-container">
            <div className= "row align-items-center responsive-padding" >
                <div className="col-12">
                    <h4>
                        <p className="text-left mb-0 font-weight-bold responsive-global-title-text"> {t('CustomersTitle1')} </p>
                        <p className="text-left font-weight-bold customers-red responsive-global-title-text" > {t('CustomersTitle2')} </p>
                    </h4>
                    <div className="card-group pt-5">
                        <div className="card border-0 customer-sizecard">
                            <div align = "center">
                                <img className="customers-img" src={ClienteLaOnza}/>
                                <div className="card-body">
                                    <div className="card-title responsive-global-description-text"> {t('Customers1Part1')} <br/> {t('Customers1Part2')} </div>
                                </div>
                            </div>
                        </div>
                        <div className="card border-0 customer-sizecard">
                            <div align = "center">
                                <img variant="top" className="customers-img" src={ClienteIGH} />
                                <div className="card-body responsive-global-description-text">
                                    <div className="card-title"> {t('Customers2Part1')} <br/> {t('Customers2Part2')} </div>
                                </div>
                            </div>
                        </div>
                        <div className="card border-0 customer-sizecard">
                            <div align = "center">
                                <img variant="top" className="customers-img2" src={Mistodemo} />
                                <div className="card-body responsive-global-description-text">
                                    <div className="card-title"> {t('Customers3Part1')} <br/> {t('Customers3Part2')} </div>
                                </div>
                            </div>
                        </div>
                        <div className="card border-0 customer-sizecard">
                            <div align = "center">                            
                                <img variant="top" className="customers-img2" src={Solmintras} />
                                <div className="card-body responsive-global-description-text">
                                    <div className="card-title"> {t('Customers4Part1')} <br/> {t('Customers4Part2')} </div>
                                </div>
                            </div>
                        </div>
                        <div className="card border-0 customer-sizecard">
                            <div align = "center">                            
                                <img variant="top" className="customers-img" src={Aura} />
                                <div className="card-body responsive-global-description-text">
                                    <div className="card-title"> {t('Customers5Part1')} <br/> {t('Customers5Part2')} </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="card-group">
                        <div className="card border-0 pr-5">
                            <img className="card-img-top customers-img" src={ClienteLaOnza} alt="Card image cap"/><br/>
                            <spam className="card-text  responsive-global-description-text">COMPAÑIA MINERA</spam>
                        </div>
                        <div className="card border-0 pr-5">
                        <div align="center">
                            <img className="card-img-top customers-img" src={ClienteIGH} alt="Card image cap"/>
                            <div className="card-body">
                                <p className="card-text responsive-global-description-text">INVERITAS GLOBAL <br/> HOLDINGS INGENIERIA</p>
                            </div>
                        </div>
                        </div>
                        <div className="card border-0 pr-5">
                        <div align="center">
                            <img className="card-img-top customers-img2" src={Mistodemo} alt="Card image cap"/>
                            <div className="card-body">
                                <p className="card-text responsive-global-description-text" >MINERA <br/> MISTODEMO</p>
                            </div>
                        </div>
                        </div>
                        <div className="card border-0 pr-5">
                        <div align="center">
                            <img className="card-img-top customers-img2" src={Solmintras} alt="Card image cap"/>
                            <div className="card-body">
                                <p className="card-text responsive-global-description-text">MINERA <br/> SOLMINTRAS</p>
                            </div>
                        </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default Customers;
