import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import servicios from './Servicios.png';
import servicios1 from './Servicios1.png';
import servicios2 from './Servicios2.png';
import servicios3 from './Servicios3.png';
import servicios4 from './Servicios4.png';
import flecha from './Flecha.svg';
import contact from './Footer.png';
import logo from './Logo.png';

import './ServicesDet.scss';


function SeeServicesInicio() {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();
    return (
        <div className="row p-0 align-items-center">
            <div className="col-5">
                <h4>
                    <p className="text-left mb-0 font-weight-bold servicedet-textwhite responsive-global-title-text"> {t('ServicesTitle1')} </p>
                    <p className="text-left mb-0 font-weight-bold servicedet-textred responsive-global-title-text"> {t('ServicesTitle2')} </p>
                </h4>
            </div>
        </div>
    );   
}

function ServicesInicio() {
    return (
        <div className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <div class="row">
                        <img className="card-img-top border-0" src={servicios} alt="Servicios Background" />
                    </div>
                    <div className="row-fluid">
                        <div className="carousel-caption identity-center text-left">
                            <SeeServicesInicio/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function SeeServicioDet1() {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();
    return (
        <div className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <div class="row">
                        <div class="col-7">
                            <div class="card responsive-padding border-0">
                                    <div class="card-body">
                                        <img src={flecha} width="15px" height="15px"/><spam className="card-title responsive-global-descriptionbold-text servicesdet-space"> {t('ServicesDetTitle1')} </spam>
                                        <p class="card-text responsive-global-description-text" align="justify">
                                            <br/>
                                            {t('ServicesDet1Part1')} <br/><br/>
                                            {t('ServicesDet1Part2')} <br/><br/>
                                            {t('ServicesDet1Part3')}
                                        </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-5">
                            <div class="card border-0">
                                <img className="card-img-top border-0" src={servicios1} alt="ServiciosDet Background" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function SeeServicioDet2() {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();
    return (
        <div className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <div class="row">
                        <div class="col-5">
                            <div class="card responsive-padding border-0 ">
                                <img className="card-img-top border-0" src={servicios2} alt="ServiciosDet Background" />
                            </div>
                        </div>
                        <div class="col-7">
                            <div class="card responsive-padding border-0">
                                <div class="card-body">
                                    <img src={flecha} width="15px" height="15px"/><spam className="card-title responsive-global-descriptionbold-text servicesdet-space"> {t('ServicesDetTitle2')} </spam>
                                    <div class="card-text responsive-global-description-text" align="justify">
                                        <ul>
                                            <br/>
                                            <li> {t('ServicesDet2Part1')} </li>
                                            <li> {t('ServicesDet2Part2')} </li>
                                            <li> {t('ServicesDet2Part3')} </li>
                                            <li> {t('ServicesDet2Part4')} </li>
                                            <li> {t('ServicesDet2Part5')} </li>
                                            <li> {t('ServicesDet2Part6')} </li>
                                            <li> {t('ServicesDet2Part7')} </li>
                                            <li> {t('ServicesDet2Part8')} </li>
                                            <li> {t('ServicesDet2Part9')} </li>
                                            <li> {t('ServicesDet2Part10')} <br/><br/></li>
                                        </ul>
                                        {t('ServicesDet2Part11')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function SeeDetalle3() {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();
    return (
        <div class="row justify-content-center">
            <div class="col-2 servicesdet-padding servicedet-bgblanco">
                <div class="row">
                    <div class="col">
                        <img src={flecha} className="servicesdet-flecha"/><spam className="responsive-global-descriptionbold-text servicedet-texblack"> {t('ServicesDetTitle3')} </spam>
                        <p class="responsive-global-description-text servicedet-texblack">
                            <ul>
                                <li> {t('ServicesDet3Part1')} </li>
                                <li> {t('ServicesDet3Part2')} </li>
                                <li> {t('ServicesDet3Part3')} </li>
                                <li> {t('ServicesDet3Part4')} </li>
                                <li> {t('ServicesDet3Part5')} </li>
                                <li> {t('ServicesDet3Part6')} </li>
                            </ul>
                        </p>
                    </div>
                </div>    
            </div>
            <div class="col-1">
            </div>
            <div class="col-2 servicesdet-padding servicedet-bgblanco">
                <div class="row">
                    <div class="col">             
                        <img src={flecha} className="servicesdet-flecha"/><spam className="responsive-global-descriptionbold-text servicedet-texblack"> {t('ServicesDetTitle4')} </spam>
                        <p class="responsive-global-description-text servicedet-texblack">
                            <ul>
                                <li> {t('ServicesDet4Part1')} </li>
                                <li> {t('ServicesDet4Part2')} </li>
                                <li> {t('ServicesDet4Part3')} </li>
                                <li> {t('ServicesDet4Part4')} </li>
                            </ul>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-1">
            </div>
            <div class="col-2 servicesdet-padding servicedet-bgblanco">
                <div class="row">
                    <div class="col">            
                        <img src={flecha} className="servicesdet-flecha"/><spam className="responsive-global-descriptionbold-text servicedet-texblack"> {t('ServicesDetTitle5')} </spam>
                        <p class="responsive-global-description-text servicedet-texblack">
                            <ul>
                                <li> {t('ServicesDet5Part1')} </li>
                                <li> {t('ServicesDet5Part2')} </li>
                                <li> {t('ServicesDet5Part3')} </li>
                                <li> {t('ServicesDet5Part4')} </li>
                            </ul>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-1">
            </div>
            <div class="col-2 servicesdet-padding servicedet-bgblanco">
                <div class="row">
                    <div class="col">     
                        <img src={flecha} className="servicesdet-flecha"/><spam className="responsive-global-descriptionbold-text servicedet-texblack"> {t('ServicesDetTitle6')} </spam>
                        <p class="responsive-global-description-text servicedet-texblack">
                            <ul>
                                <li> {t('ServicesDet6Part1')} </li>
                                <li> {t('ServicesDet6Part2')} </li>
                                <li> {t('ServicesDet6Part3')} </li>
                                <li> {t('ServicesDet6Part4')} </li>
                                <li> {t('ServicesDet6Part5')} </li>
                                <li> {t('ServicesDet6Part6')} </li>
                                <li> {t('ServicesDet6Part7')} </li>
                                <li> {t('ServicesDet6Part8')} </li>
                            </ul>
                        </p>
                    </div>
                </div>
            </div> 
        </div>
    );
}

function SeeServicioDet3() {
    return (
        <div className="row-fluid position-relative">
            <img className="img-fluid position-relative card-img-top border-0" src={servicios3} alt="ServicesDet Background" />
            <div className="position-absolute servicesdet-content-overlay servicesdet-sinpadding">
                <SeeDetalle3 />
            </div>
        </div>
    );
}

function SeeServicioDet4() {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();
    return (
        <div className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <div class="row">
                        <div class="col-5">
                            <div class="card border-0">
                                <img className="card-img-top border-0" src={servicios4} alt="ServiciosDet Background" />
                            </div>
                        </div>
                        <div class="col-7">
                            <div class="card responsive-padding border-0">
                                <div class="card-body">
                                    <img src={flecha} width="15px" height="15px"/><spam className="card-title responsive-global-descriptionbold-text servicesdet-space"> {t('ServicesDetTitle7')} </spam>
                                    <div class="card-text responsive-global-description-text" align="justify">
                                        <br/> {t('ServicesDet7Part1')} <br/><br/>
                                              {t('ServicesDet7Part2')} <br/><br/>
                                              {t('ServicesDet7Part3')} <br/>
                                        <ul>
                                            <br/>
                                            <li> {t('ServicesDet7Part4')} </li>
                                            <li> {t('ServicesDet7Part5')} </li>
                                            <li> {t('ServicesDet7Part6')} </li>
                                            <li> {t('ServicesDet7Part7')} </li>
                                            <li> {t('ServicesDet7Part8')} </li>
                                            <li> {t('ServicesDet7Part9')} </li>
                                            <li> {t('ServicesDet7Part10')} </li>
                                            <li> {t('ServicesDet7Part11')} </li>
                                            <li> {t('ServicesDet7Part12')} </li>
                                            <li> {t('ServicesDet7Part13')} </li>
                                            <li> {t('ServicesDet7Part14')} </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function SeeFooter() {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();
    return (
        <div className="row-fluid position-relative">
            <img src={logo} className="responsive-logofooter"/>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <p class="responsive-global-descriptionxs-text contact-footer">© DENDUR CORPORATION. {t('Footer')}</p>
            </div>
        </div>
    );
}

function Footer() {
    return(
        <div className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <div class="row">
                        <img className="card-img-top border-0" src={contact} alt="ServicesDet Background" />
                    </div>
                    <SeeFooter/>
                </div>
            </div>
        </div>
    );
}

function ServicesDet() {
    return (
        <div className="container-fluid p-0">
            <ServicesInicio className="position-relative"/>
            <SeeServicioDet1/>
            <SeeServicioDet2/>
            <SeeServicioDet3/>
            <SeeServicioDet4/>
            <Footer/>
        </div>
    );
}

export default ServicesDet;


