import { p, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import publications from './Publications.png';
import { Link } from 'react-router-dom'
import './Publications.scss';

function SeePublications() {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();
    return (
        <div className="row p-0 align-items-center">
            <div className="col-10">
                <h4>
                    <p className="text-left responsive-global-title-text font-weight-bold text-white mb-0 "> {t('PublicationTitle1')} </p>
                    <p className="text-left responsive-global-title-text font-weight-bold publications-red"> {t('PublicationTitle2')} </p>
                </h4>
                <p className="text-left responsive-global-description-text text-white "> {t('Publication1')} </p>
                <br/><br/>
                <a class="button text-button" role="button"><Link to="/publishing" className="button-link"> {t('PublicationButton')} </Link></a>
            </div>
        </div>
    );
}

function Publications() {
    return (
        <div className="row-fluid position-relative">
            <img className="img-fluid position-relative card-img-top border-0" src={publications} alt="Publications background" />
            <div className="position-absolute publications-content-overlay responsive-padding">
                <SeePublications />
            </div>
        </div>
    );
}

export default Publications;

