import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import publications from './Publications.png';
import flecha from './Flecha.svg';
import imgpubli1 from './Image1.png';
import imgpubli2 from './Image2.png';
import imgpubli3 from './Image3.png';
import contact from './Footer.png';
import logo from './Logo.png';
import './PublicationsDet.scss';

function Contenido() {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();
    return(
        <div className="row-fluid responsive-padding">
                <img src={flecha} width="15px" height="15px"/><spam className="card-title responsive-global-descriptionbold-text servicesdet-space"> {t('PublicationDet1')} </spam>
                <br/><br/>
                <div class="card m-2 border-0">
                    <div class="row">
                        <div class="col-md-1">
                            <img src={imgpubli1} alt="..." width="120px" height="120px"/>
                        </div>
                        <div class="col-md-11">
                            <div class="card-body responsive-global-descriptionxl-text text-black">
                                <a class="card-title" href="Publicacion1.pdf" download={t('PublicationDetTit1')}>{t('PublicationDetTit1')}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card m-2 border-0">
                    <div class="row">
                        <div class="col-md-1">
                            <img src={imgpubli2} alt="..." width="120px" height="120px"/>
                        </div>
                        <div class="col-md-11">
                            <div class="card-body responsive-global-descriptionxl-text text-black">
                                <a class="card-title" href="Publicacion2.pdf" download={t('PublicationDetTit2')}>{t('PublicationDetTit2')}</a>
                                <br/>
                                <a class="card-title" href="Publicacion4.pdf" download={t('PublicationDetTit3')}>{t('PublicationDetTit3')}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card m-2 border-0">
                    <div class="row">
                        <div class="col-md-1">
                            <img src={imgpubli2} alt="..." width="120px" height="120px"/>
                        </div>
                        <div class="col-md-11">
                            <div class="card-body responsive-global-descriptionxl-text text-black">
                                <a class="card-title" href="Publicacion3.pdf" download={t('PublicationDetTit4')}>{t('PublicationDetTit4')}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card m-2 border-0">
                    <div class="row">
                        <div class="col-md-1">
                            <img src={imgpubli3} alt="..." width="120px" height="120px"/>
                        </div>
                        <div class="col-md-11">
                            <div class="card-body responsive-global-descriptionxl-text text-black">
                                <a class="card-title" href="Publicacion5.pdf" download={t('PublicationDetTit5')}>{t('PublicationDetTit5')}</a>
                            </div>
                        </div>
                    </div>
                </div>  
                <br/><br/>
                <img src={flecha} width="15px" height="15px"/><spam className="card-title responsive-global-descriptionbold-text servicesdet-space"> {t('PublicationDet2')} </spam>
                <br/><br/>
                <img src={flecha} width="15px" height="15px"/><spam className="card-title responsive-global-descriptionbold-text servicesdet-space"> {t('PublicationDet3')} </spam>
                <br/>
        </div>
    );                                    
}

function SeeFooter() {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();
    return (
        <div className="row-fluid position-relative">
            <img src={logo} className="responsive-logofooter"/>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <p class="responsive-global-descriptionxs-text contact-footer">© DENDUR CORPORATION. {t('Footer')}</p>
            </div>
        </div>
    );
}

function Footer() {
    return(
        <div className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <div class="row">
                        <img className="card-img-top border-0" src={contact} alt="PublicationsDet Background" />
                    </div>
                    <SeeFooter/>
                </div>
            </div>
        </div>
    );
}

function SeePublicationsDetHeader() {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();
    return (
        <div className="row p-0 align-items-center">
            <div className="col-5">
                <h4>
                    <p className="text-left responsive-global-title-text font-weight-bold text-white mb-0 "> {t('PublicationTitle1')} </p>
                    <p className="text-left responsive-global-title-text font-weight-bold publicationsdet-red"> {t('PublicationTitle2')} </p>
                </h4>
            </div>
        </div>
    );
}

function PublicationsDetHeader() {
    return (
        <div className="row-fluid position-relative">
            <img className="img-fluid position-relative card-img-top border-0" src={publications} alt="Publications background" />
            <div className="position-absolute publicationsdet-content-overlay responsive-padding">
                <SeePublicationsDetHeader/>
            </div>
        </div>
    );
}

function PublicationsDet() {
    return (
        <div className="row-fluid">
            <PublicationsDetHeader/>
            <Contenido/>
            <Footer/>
        </div>
    );
}


export default PublicationsDet;

