import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import leftimage from './Left.png';
import rightimage from './Right.png';
import tractor from './Tractor.png';
import './Identity.scss';
    
function SeeInicio() {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();
    return (
        <div className="row p-0 align-items-center">
            <div className="col-5">
                <h4>
                    <p className="text-left responsive-global-bigtitle-text font-weight-bold text-white m-0 p-0">{t('Title1')}</p>
                    <p className="text-left responsive-global-bigtitle-text font-weight-bold identity-titlered m-0 p-0">{t('Title2')}</p>
                    <p className="text-left responsive-global-bigtitle-text font-weight-bold text-white m-0 p-0">{t('Title3')}</p>
                </h4>
                <p class="text-white responsive-global-description-text">{t('TitleDescription')}<br/><br/></p>
                <a class="button text-button" href="/home#idcontacto" role="button">{t('TitleButton')}</a>
            </div>
        </div>
    );
}

function Inicio() {
    return (
        <div className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <div class="row">
                        <div class="col-6 p-0 border-0 m-0">
                            <img className="card-img-top border-0" src={rightimage} alt="Identity Background" />
                        </div>
                        <div class="col-6 p-0 border-0 m-0">
                            <div class="card p-0 border-0 m-0">
                                <img className="card-img-top border-0" src={leftimage} alt="Brochure Background" />
                            </div>
                        </div>
                    </div>
                    <div className="row-fluid">
                        <div className="carousel-caption identity-center text-left">
                            <SeeInicio/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function Overlay() {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();
    return (
        <div class="col-4 position-absolute overlay card identity-background-image">
            <div class="card-body">
                <p class="card-text responsive-global-overlay-red responsive-global-text-overlay text-white text-uppercase">
                <span class="responsive-global-textbold-overlay">DENDUR </span> {t('OverlayRed1')} <span class="responsive-global-textbold-overlay"> {t('OverlayRed2')} </span> {t('OverlayRed3')} <span class="responsive-global-textbold-overlay"> {t('OverlayRed4')} </span> {t('OverlayRed5')} <span class="responsive-global-textbold-overlay"> {t('OverlayRed6')} </span> {t('OverlayRed7')}
                </p>
            </div>
        </div>
    );
}

function WhoWeAre() {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();
    return (
        <div className= "row-fluid responsive-padding" >
            <div class="col-6 card border-0">
                <div class="card-body">
                     <h4>
                        <p class="card-title responsive-global-title-text font-weight-bold mb-0 ">{t('WhoweareTitle1')} </p>
                            <p class="card-title responsive-global-title-text font-weight-bold mb-0 identity-titlered responsive-paddingbottom"> {t('WhoweareTitle2')}</p>
                        </h4>
                        <p class="card-text responsive-global-descriptionxl-text text-black" align="justify">
                        <span class="responsive-global-descriptionxlbold-text">DENDUR</span> {t('WhowearePart1')} <span class="responsive-global-descriptionxlbold-text">{t('WhowearePart2')}</span>
                        </p>
                        <p class="card-text responsive-global-description-text text-black" align="justify">
                            {t('WhowearePart3')}
                        </p>
                    </div>
                </div>
            </div>
        
    );
}

function Identity() {
    return (
        <div className="container-fluid p-0">
            <div id="idhome">
                <Inicio className="position-relative"/>
            </div>
            <div className="row-fluid position-relative p-0">
                <div id="idnosotros">
                    <Overlay />
                </div>
                <WhoWeAre/>
                <img className="img-fluid p-0 card-img-top border-0" src={tractor} alt="Tractor background" /> 
            </div>
        </div>
    );
}

export default Identity;
