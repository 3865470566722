import { Switch, Route, Redirect } from 'react-router-dom'
import Home from './Home';
import Publishing from './Publishing';
import ServicesDetail from './ServicesDetail';

function Controller() {
    return (
        <div>
            <Route path='/'> <Redirect to="/home" /> </Route>
            <Route path='/home' render={() => <Home />} />
            <Route path='/publishing' render={() => <Publishing/>} />
            <Route path='/services' render={() => <ServicesDetail/>} />
        </div>
    );
}

export default Controller;

