import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import brochure from './Brochure.png';
import './Brochure.css';

function BrochureDownload() {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();
    return (
        <div className="row p-0 align-items-center">
            <div className="col-12">
                <p className="text-left font-weight-normal responsive-global-description-text"> {t('Brochure1')} </p>
                <h4>
                    <p className="text-left responsive-global-title-text font-weight-bold mb-0"> {t('BrochureTitle1')} </p>
                    <p className="text-left responsive-global-title-text font-weight-bold mb-0"> {t('BrochureTitle2')}</p>
                    <p className="text-left responsive-global-title-text font-weight-bold mb-0 brochure-red"> {t('BrochureTitle3')} </p>
                    <p className="text-left responsive-global-title-text font-weight-bold brochure-red"> {t('BrochureTitle4')} </p>
                </h4>
                <form>
                    <div className="form-group">
                        <label className="responsive-global-description-text">Email:</label>
                        <input type="email" className="form-control input-form" placeholder= {t('BrochureEmail')} />
                    </div>
                    <br/>
                    <a class="button text-button" role="button" href={ i18n.language === 'en' ? "BrochureDENDUR_EN.pdf" : "BrochureDENDUR_ES.pdf"} download="Brochure"> {t('BrochureButton')} </a>
                    {/* <button type="submit" className="button text-button">DESCARGAR</button> */}
                </form>
            </div>
        </div>
    );
}

function Brochure() {
    return (
        <div className="row-fluid position-relative">
            <img className="img-fluid position-relative card-img-top border-0" src={brochure} alt="Brochure background" />
            <div className="position-absolute brochure-content-overlay responsive-padding">
                <BrochureDownload />
            </div>
        </div>
    );
}





export default Brochure;

