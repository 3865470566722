import ServicesDet from '../components/ServicesDet/ServicesDet';

function ServicesDetail() {
    return (
        <div>
            <ServicesDet/>
        </div>
    );
}

export default ServicesDetail;