import React, { Suspense } from 'react';

// Components imports
import Header from './components/Header/Header';
import { BrowserRouter } from 'react-router-dom'

import Controller from './layout/Controller';

import logo from './logo.svg';
import './App.css';

// loading component for suspense fallback
const Loader = () => (
    <div className="App">
      <img src={logo} className="App-logo" alt="logo" />
      <div>loading...</div>
    </div>
  );

function App() {
    return (
        <Suspense fallback={<Loader />}>
            <BrowserRouter>
                <Header />
                <Controller />
            </BrowserRouter>
        </Suspense>
    );
}

export default App;
