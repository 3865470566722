import mapa from './Mapa.svg';
import model from './Model.png';
import './Team.scss';
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

function SeeOurTeam() {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();
    return (
        <div className="row p-0 align-items-center">
            <div className="col-5">
                <h4>
                    <p className="text-left mb-0 responsive-global-titlereg-text">{t('OurTeamSubTitle1')}</p>
                    <p className="text-left mb-0 responsive-global-title-text">{t('OurTeamSubTitle2')}</p>
                </h4>
            </div>
        </div>
    );
}

function OurTeam() {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();
    return (
        <div className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <div class="row">
                        <div class="col-6 p-0 border-0 m-0">
                            <div class="card p-0 border-0 m-0">
                                <img className="card-img-top border-0" src={mapa} alt="Team Background" />
                            </div>
                            <br/><br/><br/><br/><br/><br/><br/>
                        </div>
                        <div class="col-6 p-0 border-0 m-0">
                            <div className="container-fluid">
                                <div className= "row responsive-padding">
                                    <div class="card border-0">
                                        <div class="card-body">
                                            <h4>
                                                <p class="card-title responsive-global-title-text font-weight-bold mb-0"> {t('OurTeamTitle1')} </p>
                                                <p class="card-title responsive-global-title-text font-weight-bold responsive-paddingbottom team-textred "> {t('OurTeamTitle2')} </p>
                                            </h4>
                                            <p class="card-text responsive-global-description-text text-black" align="justify">
                                            <span class="responsive-global-descriptionbold-text">DENDUR</span> {t('OurTeam1')} <span class="responsive-global-descriptionbold-text"> {t('OurTeam2')}</span> {t('OurTeam3')} <span class="responsive-global-descriptionbold-text team-textred"> {t('OurTeam4')}</span>  {t('OurTeam5')} <span class="responsive-global-descriptionbold-text"> {t('OurTeam6')} </span> {t('OurTeam7')}
                                            </p>
                                            <p class="card-text text-black responsive-global-description-text" align="justify">
                                                {t('OurTeam8')} <span class="responsive-global-descriptionbold-text">DENDUR</span>  {t('OurTeam9')} <span class="responsive-global-descriptionbold-text"> {t('OurTeam10')}</span>  {t('OurTeam11')}<br/><br/>
                                                {t('OurTeam12')}
                                            </p>
                                            <br/><br/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row-fluid">
                        <div className="carousel-caption team-center text-left">
                            <SeeOurTeam/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function Overlay() {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();
    return (
        <div class="col-4 team-zposition position-absolute overlay team-background-image">
            <div class="card-body">
                <p class="card-text responsive-global-overlay-black responsive-global-text-overlay text-uppercase text-white ">
                <span class="responsive-global-textbold-overlay"> {t('OverlayBlack1')} </span> {t('OverlayBlack2')} <span class="responsive-global-textbold-overlay"> {t('OverlayBlack3')} </span> {t('OverlayBlack4')} <span class="responsive-global-textbold-overlay"> {t('OverlayBlack5')} </span>
                </p><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
            </div>
        </div>
    );
}

function SeeDifference() {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();
    return (
        <div className="row responsive-padding">
            <div className="col-6">
                <h4>
                    <p class="responsive-global-title-text font-weight-bold mb-0"> {t('DiferenceTitle1')} </p>
                    <p class="responsive-global-title-text responsive-paddingbottom font-weight-bold mb-0 team-textred"> {t('DiferenceTitle2')} </p>
                </h4>
                <p class="responsive-global-descriptionxl-text text-black" align="justify">
                <span class="responsive-global-descriptionxlbold-text">DENDUR</span> {t('Diference1')} <span class="responsive-global-descriptionxlbold-text"> {t('Diference2')} </span> {t('Diference3')}
                </p>
                <p class="responsive-global-description-text text-black" align="justify">
                {t('Diference4')} <span class="responsive-global-descriptionbold-text"> {t('Diference5')} </span>
                </p>
            </div>
        </div>
    );
}

function Difference() {
    return (
        <div className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img className="w-100 img-fluid" src={model} alt="Team Background" />
                    <div className="row-fluid">
                        <div className="carousel-caption team-centertop text-left" id="iddiferencia">
                            <SeeDifference />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


function Team() {
    return (
        <div className="container-fluid p-0">
            <OurTeam className="position-relative"/>
            <div className="row-fluid position-relative p-0">
                <Overlay />
                <Difference />
            </div>
        </div>
    );
}


export default Team;